import * as ActiveStorage from '@rails/activestorage'

const dispatchEvent = (element, type, eventInit = {}) => {
  const { disabled } = element
  const { bubbles, cancelable, detail } = eventInit
  const event = document.createEvent('Event')

  event.initEvent(type, bubbles || true, cancelable || true)
  event.detail = detail || {}

  try {
    element.disabled = false
    element.dispatchEvent(event)
  } finally {
    element.disabled = disabled
  }

  return event
}

export class DirectUploadController {
  constructor(input, file) {
    this.input = input
    this.file = file
    this.directUpload = new ActiveStorage.DirectUpload(
      this.file,
      this.url,
      this
    )
    this.dispatch('initialize')
  }

  start(callback) {
    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'hidden'
    hiddenInput.name = this.input.name
    const element =
      document.getElementById(`direct-upload-${this.directUpload.id}`) ||
      this.input

    element.insertAdjacentElement('afterbegin', hiddenInput)

    this.dispatch('start')

    this.directUpload.create((error, attributes) => {
      if (error) {
        hiddenInput.parentNode.removeChild(hiddenInput)
        this.dispatchError(error)
      } else {
        hiddenInput.value = attributes.signed_id
      }

      this.dispatch('end')
      callback(error)
    })
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100
    if (progress) {
      this.dispatch('progress', { progress })
    }
  }

  get url() {
    return this.input.getAttribute('data-direct-upload-url')
  }

  dispatch(name, detail = {}) {
    detail.file = this.file
    detail.id = this.directUpload.id
    return dispatchEvent(this.input, `direct-upload:${name}`, { detail })
  }

  dispatchError(error) {
    const event = this.dispatch('error', { error })
    if (!event.defaultPrevented) {
      alert(error)
    }
  }

  // DirectUpload delegate

  directUploadWillCreateBlobWithXHR(xhr) {
    this.dispatch('before-blob-request', { xhr })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.dispatch('before-storage-request', { xhr })
    xhr.upload.addEventListener('progress', (event) =>
      this.uploadRequestDidProgress(event)
    )
  }
}
