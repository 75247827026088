// Autogrows textareas based on content
//
// Example Usage:
// <%= form.text_area :value, data: {controller: "autogrow", action: "input->autogrow#autogrow"} %>

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.autogrow()
  }

  appear(entry, observer) {
    this.autogrow()
  }

  autogrow() {
    const mainElement = document.querySelector('main')
    const scrollOffset = mainElement.scrollTop

    window.setTimeout(() => {
      this.element.style.height = 'auto'
      this.element.style.height = `${this.element.scrollHeight + 20}px`

      mainElement.scrollTop = scrollOffset
    }, 10)
  }
}
