import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation, index) => {
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((addedNode, nodeIndex) => {
            if (addedNode.nodeType === Node.ELEMENT_NODE) {
              addedNode.classList.add('fade-in-bottom', 'opacity-0')
            }
          })
        }
      })
    })

    this.observer.observe(this.element, {
      childList: true,
      subtree: false
    })
  }

  disconnect() {
    this.observer.disconnect()
  }
}
