// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from './application'

// Register each controller with Stimulus
import controllers from './**/*_controller.js'
// import chat_controllers from '../../../apps/plugin/javascript/**/*_controller.js'

import textFadeInEffectController from '../../../apps/plugin/javascript/controllers/text_fadein_effect_controller.js'
application.register('text-fadein-effect', textFadeInEffectController)

import messagesPushUpEffectController from '../../../apps/plugin/javascript/controllers/messages_push_up_effect_controller.js'

application.register('messages-push-up-effect', messagesPushUpEffectController)

controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import {
  Dropdown,
  Modal,
  Tabs,
  Popover,
  Toggle,
  Slideover
} from 'tailwindcss-stimulus-components'
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)
