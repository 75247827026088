import Alpine from 'alpinejs'

//dev.to/iamcherta/hotwire-empty-states-with-alpinejs-4gpo
https: Alpine.directive(
  'mutation-observer',
  (el, { expression, modifiers }, { evaluateLater, cleanup }) => {
    let callback = evaluateLater(expression)

    let observer = new MutationObserver(() => {
      callback()
    })

    observer.observe(el, {
      childList: modifiers.includes('child-list'),
      attributes: modifiers.includes('attributes'),
      characterData: modifiers.includes('character-data'),
      subtree: modifiers.includes('subtree')
    })

    cleanup(() => {
      observer.disconnect()
    })
  }
)
