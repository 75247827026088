import { Controller } from '@hotwired/stimulus'
import { DirectUploadController } from '../src/direct_uploads_enhanced'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['input', 'videoUploaded', 'chooseVideo', 'uploadingSlot']

  connect() {
    const template = this.element.getElementsByTagName('template')[0].innerHTML

    this.element.addEventListener('direct-upload:initialize', (event) => {
      const { target, detail } = event
      const { id, file } = detail

      this.uploadingSlotTarget.insertAdjacentHTML(
        'beforebegin',
        template.replaceAll('${id}', id).replace('${file.name}', file.name)
      )
    })

    this.element.addEventListener('direct-upload:start', (event) => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.remove('direct-upload--pending')
    })

    this.element.addEventListener('direct-upload:progress', (event) => {
      const { id, progress } = event.detail
      const progressElement = document.getElementById(
        `direct-upload-progress-${id}`
      )
      progressElement.style.width = `${progress}%`
    })

    this.element.addEventListener('direct-upload:error', (event) => {
      event.preventDefault()
      const { id, error } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add('direct-upload--error')
      element.setAttribute('title', error)
    })

    this.element.addEventListener('direct-upload:end', (event) => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add('direct-upload--complete')
      // this.videoUploadedTarget.style.display = 'block'

      const form = element.closest('form')
      post(form.action, {
        body: new FormData(form),
        responseKind: 'turbo-stream'
      }).then(() => {
        form.remove()
        // const messagesFrame = document.getElementById('messages-component')
        // messagesFrame.src = messagesFrame.dataset.setSrcTo
        // const documentsFrame = document.getElementById('documents-component')
        // documentsFrame.src = documentsFrame.dataset.setSrcTo
      })
    })
  }

  openPicker(event) {
    this.inputTarget.click()
  }

  change(event) {
    // this.element.classList.add('uploading')
    Array.from(this.inputTarget.files).forEach((file) => {
      this.uploadFile(file)
    })
    // this.chooseVideoTarget.style.display = 'none'

    this.inputTarget.value = null
  }

  uploadFile(file) {
    new DirectUploadController(this.inputTarget, file).start(() => {})
  }
}
