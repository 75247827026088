import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:submit-end', this.clear.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.clear.bind(this))
  }

  clear(event) {
    const { detail, target } = event
    const form = target

    for (let e of form.elements) {
      if (e.type != 'text') continue
      e.value = ''
    }
  }
}
